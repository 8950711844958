import { gql } from '@apollo/client';

export const QUERY_EXTENDEDUSERORG = gql`
query UserOrg($firebaseUid: String!) {
  userOrg(firebaseUid: $firebaseUid) {
    name
    level
    dashboardConfig {
      showDashboard11
      showDashboard21
      showDashboard31
      showStations11
      showStations31
      showUsers11
      showUsers31
      showEnergy11
      showEnergyHistory11
      showEnergyLoad11
      showEnergy21
      showEnergy31
      showEnergyHistory31
      showEnergyLoad31
      showVehicles11
      showLocations11
      showAccount11
      showAccount21
      showAccount31   
    }
    organization {
      _id
      orgName
      timeZone
      accessCode
      smartCarApiKey
      smartCarApiAccessToken
      vehicleMaxLimit
      isDemo
      defaultLat
      defaultLong
      defaultZoom
      prefFullyCharged
      prefAttentionCharge
      chargers
      fleet {
        vehicleId
        vehicleName
        vehicleYear
        vehicleMake
        vehicleModel
        vehicleStyle
        vehicleSearchTerm
        vehicleAuthCode
      }
    }
  }
}
`;

export const QUERY_STATIONINFO = `
query StationInfo($snArray: [String]!, $orderNumber: String!) {
  stationInfo(snArray: $snArray, orderNumber: $orderNumber) {
    _id
    stationName
    stationMode
    stationPower
    stationStatus
    stationStatusColor
    stationSN
    stationTimeZone
    stationVoltage
    stationRatedCurrent
    stationConnection
  }
}
`;

export const USER_TAGS = `
query UserTags($accessCode: String!) {
  userTags(accessCode: $accessCode) {
    _id
    sn
    tagType
    status
    isFreeTag
    isPaidChargingOnly
    customId
    name
    email
    createdAt
    lastUpdatedAt
  }
}
`;

export const USER_TAG_CURRENT = gql`
query UserTagCurrent($id: String!, $accessCode: String!) {
  userTagCurrent(_id: $id, accessCode: $accessCode) {
    _id
    sn
    tagType
    status
    isFreeTag
    isPaidChargingOnly
    customId
    name
    email
    createdAt
    lastUpdatedAt
  }
}
`;

export const TX_BY_IDTAG = gql`
query TxByIdTag($id: String!, $accessCode: String!, $days: Int!) {
  txByIdTag(_id: $id, accessCode: $accessCode, days: $days) {
    _id
    clientId
    stationName
    connectorId
    txId
    isActive
    startTime
    stopTime
    energy
    chargeTotal
    paymentStatus
  }
}
`;

export const QUERY_STATIONINFO_DASHBOARD = gql`
query StationInfo($snArray: [String]!, $orderNumber: String!) {
  stationInfo(snArray: $snArray, orderNumber: $orderNumber) {
    _id
    stationName
    stationMode
    stationPower
    stationStatus
    stationStatusColor
    stationSN
    stationTimeZone
    stationVoltage
    stationRatedCurrent
    stationConnection
  }
}
`;

export const QUERY_GET_ALL_RATE = gql`
query GetAllRates($userTagId: String!, $accessCode: String!) {
  getAllRates(userTagId: $userTagId, accessCode: $accessCode) {
    flatRate
    energyRate
    chargingTimeRate
    idlingRate
    rateLevel
    station
  }
}
`;

export const STATION_NAMES = gql`
query StationName($snArray: [String]!, $orderNumber: String!) {
  stationName(snArray: $snArray, orderNumber: $orderNumber) {
    chargerName
    sn
  }
}
`;
